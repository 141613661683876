<template>
  <div class="nav-top " id="nav">
    <div class="content nav-content">
      <img class="logo" src="../../assets/logo.png" alt="">
      <!--        <ul class="nav-list">-->
      <!--            <li v-for="(item,index) in list" :key="'nav'+index" @click="jumpPage(item.url)"-->
      <!--             :class="urlName===item.name?'active':''">{{ item.title }}</li>-->
      <!--        </ul>-->
      <el-menu
          class="nav-list"
          :default-active="activeIndex"
          mode="horizontal"
          :active-text-color="'#1b73e5'"
          @select="handleSelect">
        <template v-for="(item, index) in list">
          <!-- 普通菜单项 -->
          <el-menu-item
              :key="item.index"
              :index="item.index"
              :disabled="item.disabled">
            {{ item.title }}
          </el-menu-item>
        </template>
      </el-menu>
      <div class="nav-mobile">
        <img src="../../assets/top-i.png" alt="" style="width: 22px;margin-right: 13px;">
        <span>021-3653 2622</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: sessionStorage.getItem('navActiveKey') || '1',
      isPageHidden: false, // 显式声明数据项
      list: [
        {index: '1', title: '首页', name: 'index', url: '/'},
        {
          index: '2',
          title: '数字企业解决方案',
          name: 'programme',
          url: '/programme?type=2',
          path: '/programme',
        },
        {
          index: '3',
          title: '数字政府解决方案',
          name: 'programme',
          url: '/programme?type=1',
          path: '/programme',
        },
        {index: '4', title: '标杆案例', name: 'case', url: '/case'},
        // { title:'新闻动态',name:'news',url:'/news' },
        {index: '5', title: '关于我们', name: 'about', url: '/about'},
      ]
    }
  },
  mounted() {
    window.addEventListener('beforeunload', this.handleBeforeUnload);
    document.addEventListener('visibilitychange', this.handleVisibilityChange);
  },
  beforeDestroy() { // Vue 2.x
    window.removeEventListener('beforeunload', this.handleBeforeUnload);
    document.removeEventListener('visibilitychange', this.handleVisibilityChange);
  },
  methods: {
    handleVisibilityChange() {
      // 页面变为隐藏状态时（关闭标签或切换标签）
      if (document.visibilityState === 'hidden') {
        this.isPageHidden = true;
      }
    },
    // 优化菜单选择方法（L89-92）
    handleSelect(key) {
      sessionStorage.setItem('navActiveKey', key);
      const target = this.list.find(item => item.index === key);
      if (target) this.$router.push(target.url);
    }
  }
}
</script>

<style lang="scss" scoped>
.logo {
  flex-shrink: 0;
  width: 104px;
  margin-right: 84px;
}

.nav-top {
  background: #fff;
}

.nav-content {
  display: flex;
  align-items: center;
}

:deep(.el-menu--horizontal>.el-submenu .el-submenu__title){
  line-height:82px;
}
:deep(.el-submenu__title){
  font-size: 16px;
}
.nav-list {
  display: flex;
  align-items: center;

  li {
    font-size: 16px;
    font-weight: 400;
    margin-right: 58px;
    line-height: 80px;
    cursor: pointer;

    &.active {
      color: var(--color);

      &::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 35px;
        height: 3px;
        background: var(--color);
      }
    }
  }
}

.nav-mobile {
  display: flex;
  align-items: center;
}

.nav-mobile {
  margin-left: auto;
}
</style>
